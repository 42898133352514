import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/prefer-default-export
export default function inpDetails() {
  const toast = useToast()

  const location = ref({
    full: null,
    streetNumber: null,
    city: null,
    state: null,
    country: null,
    postalCode: null,
  })

  const donationCategories = ['Zakah', 'Fitr', 'Sadakah', 'other']

  const inpoDetailsData = ref({
    name: null,
    lat: '30.98052',
    lng: '30.98052',
    logo: null,
    cover: null,
    about: null,
    phone_number: null,
    email: null,
    social_facebook: null,
    social_twitter: null,
    social_instagram: null,
    social_youtube: null,
    website: null,
    class_id: 1,
    sub_class_id: null,
    address_type: 1,
    street: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
    service_name: null,
    service_description: null,
    language_id: null,
    tag_id: null,
    payment_type: null,
    business_type: null,
    business_name: null,
    legal_name: null,
    account_holder_name: null,
    account_holder_position: null,
    account_holder_dofb: null,
    transit_number: null,
    institution_number: null,
    account_number: null,
    phoneNum: null,
    charitable_number: null,
    donation_cats_id: null,
  })
  const getLang = () => store.dispatch('inpoModule/getLanguages')
  getLang()

  const languages = computed(() => store.state.inpoModule.languages)

  const getTags = () => store.dispatch('inpoModule/getTags')
  getTags()

  const tags = computed(() => store.state.inpoModule.tags)

  const getDC = () => store.dispatch('inpoModule/getDonationCategories')
  getDC()

  const DC = computed(() => store.state.inpoModule.donationCategories)

  const getClass = () => store.dispatch('inpoModule/getClassification')
  getClass()
  const classifications = computed(() => store.state.inpoModule.classifications)

  const geSubtClass = () => store.dispatch('inpoModule/getSubClassification')
  geSubtClass()

  // watchEffect(() => store.dispatch('inpoModule/getSubClassification'))

  const getSubClass = computed(() => store.state.inpoModule.subclassifications)

  const addInpo = () => {
    store.dispatch('inpoModule/addInpo', inpoDetailsData.value).then(res => {
      if (res.data.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push('/organizations')
      // eslint-disable-next-line no-empty
      } else {}
    }).catch(err => {
      if (err.response.data.errors.name) {
        console.log(err.response.data.errors)
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.name.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } if (err.response.data.errors.phone_number) {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.phone_number.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } if (err.response.data.errors.email) {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.email.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    })
  }

  return {
    location,
    donationCategories,
    inpoDetailsData,
    addInpo,
    languages,
    tags,
    DC,
    getSubClass,
    classifications,
  }
}

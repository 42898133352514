<template>
  <div>
    <!-- BODY -->
    <form-wizard
      color="#00A5AF"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="addInpo()"
    >
      <tab-content
        title="Inpo Details"
      >
        <inpo-details
          ref="reflocations"
          :inpo-details="inpoDetailsData"
          :options="options"
          :donation-categories="donationCategories"
          :classifications="classifications"
          :sub-classifications="getSubClass"
        />
      </tab-content>
      <tab-content
        title="Second"
      >
        <inpo-seconde
          :inpo-details="inpoDetailsData"
          :options="languages"
          :tag-options="tags"
          :donation-categories="DC"
        />
      </tab-content>
      <tab-content
        title="Payment Details"
      >
        <payment-details
          :payment-details-data="inpoDetailsData"
        />
      </tab-content>
      <tab-content
        title="Finish"
      >
        <inpo-add-last
          :inpo-add-last-data="inpoAddLastData"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive, computed,
} from '@vue/composition-api'
import store from '@/store'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
// eslint-disable-next-line no-unused-vars
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import inpoDetails from './InpoDetails.vue'
import inpoAddLast from './InpoAddLast.vue'
import Inpo from './Inpo'
import InpoSeconde from './InpoSeconde.vue'
import paymentDetails from './PaymentDetails.vue'
import inpoModule from './inpoModule'

Vue.use(VueSweetalert2)

export default {
  components: {
    FormWizard,
    TabContent,
    inpoDetails,
    inpoAddLast,
    InpoSeconde,
    paymentDetails,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      variant: 'dark',
      typeUrl: 'http://entities-microsevice.muslimdo.com/api/internalops/entity_types/list',
      classificationUrl: 'http://entities-microsevice.muslimdo.com/api/internalops/entity_classifications/list',
      typeOptions: [],
      classificationOptions: [],
      option: ['Canada', 'United States'],
      options: ['English', 'Arabic', 'German', 'other'],
      autocomplete: null,
      place: null,
    }
  },
  setup() {
    const INPO_MODULE = 'inpoModule'

    // Register module
    if (!store.hasModule(INPO_MODULE)) store.registerModule(INPO_MODULE, inpoModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INPO_MODULE)) store.unregisterModule(INPO_MODULE)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      donationCategories,
      paymentDetailsData,
      inpoDetailsData,
      addInpo,
      languages,
      tags,
      DC,
      getSubClass,
      classifications,
    } = Inpo()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      donationCategories,
      paymentDetailsData,
      inpoDetailsData,
      addInpo,
      languages,
      tags,
      DC,
      getSubClass,
      classifications,
    }
  },
  created() {
    this.getType()
    this.getClassification()
  },
  mounted() {
    this.initAutocomplete()
  },
  methods: {
    getType() {
      axios.get(this.typeUrl).then(res => {
        this.typeOptions = res.data.data.data
      })
    },
    getClassification() {
      axios.get(this.classificationUrl).then(res => {
        this.classificationOptions = res.data.data.data
      })
    },
    addNewORG() {
      axios.post('http://entities-microsevice.muslimdo.com/api/internalops/entity', this.inpoDetailsData).then(res => {
        if (res.data.status === 200) {
          console.log(res.data)
          this.show = false
          this.$swal(
            'Added!',
            'New Organization has been Added.',
            'success',
          )
          this.$parent.$refs.table.refresh()
        // eslint-disable-next-line no-empty
        } else {}
      }).catch(err => {
        if (err.response.data.errors.name) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.name.toString(),
              icon: 'BellIcon',
            },
          })
        } if (err.response.data.errors.phone_number) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.phone_number.toString(),
              icon: 'BellIcon',
            },
          })
        } if (err.response.data.errors.email) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.email.toString(),
              icon: 'BellIcon',
            },
          })
        }
      })
    },
    initAutocomplete() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.reflocations.$refs.reflocation, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      })
      console.log(this.autocomplete)
      this.autocomplete.addListener('place_changed', this.fillInAddress)
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace()
      this.place = place
      console.log(place)
      // eslint-disable-next-line no-restricted-syntax
      for (const component of this.place.address_components) {
        const componentType = component.types[0]
        // eslint-disable-next-line default-case
        switch (componentType) {
          case 'street_number': {
            this.inpoDetailsData.street = `${component.long_name}, `
            break
          }
          case 'route': {
            this.inpoDetailsData.street += component.short_name
            break
          }
          case 'postal_code': {
            this.inpoDetailsData.postal_code = component.long_name
            break
          }
          case 'locality':
            this.inpoDetailsData.city = component.long_name
            break
          case 'administrative_area_level_1': {
            this.inpoDetailsData.state = component.short_name
            break
          }
          case 'country':
            this.inpoDetailsData.country = component.short_name
            break
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
